
import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon,MDBCard,MDBProgress,MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter  } from "mdbreact";
import { ToastContainer, toast } from 'react-toastify';
import { Form} from "../../data/index";

const { GetFormularyById, updateFormularyById } = Form
 class ModalUpdate extends Component {
    constructor(props){
        super(props)
        this.state = {
            loadingData:false,
            loading: false,
            entity: '',
            idInstitution: '',
            nameInstitution:'',
            idService: '',
            nameService: '',
            id:'',
            versionForm: '',
            created: '',
            url: '',
            idForm:'',
            validate: 1
        }
    }


    handleInput(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){
            
            if(nextProps.idForm){
                const result = await GetFormularyById(nextProps.id)
                console.log('hola',result);
                if(result.data.Formulary.length>0){
                    this.setState({
                        entity: result.data.Formulary[0].entity,
                        idInstitution:result.data.Formulary[0].idinstitution,
                        nameInstitution:result.data.Formulary[0].institution_name,
                        idService:result.data.Formulary[0].idservice,
                        nameService:result.data.Formulary[0].service_name,
                        url:result.data.Formulary[0].url,
                        idForm: result.data.Formulary[0].idForm,
                        id:result.data.Formulary[0].id,
                        created:result.data.Formulary[0].createdAt,
                        versionForm:result.data.Formulary[0].versionForm,
                        validate:result.data.Formulary[0].validate !== null ? result.data.Formulary[0].validate : 1
                    })
                }
            }
        }
        
        
    }

    async saveForm(){
        let body = {
            entity: this.state.entity,
            idinstitution: this.state.idInstitution,
            service_name: this.state.nameService,
            institution_name: this.state.nameInstitution,
            idservice: this.state.idService,
            url: this.state.url,
            idForm: this.state.idForm,
            versionForm: this.state.versionForm,
            validate:this.state.validate
        }
        const result = await  updateFormularyById(body,this.props.id)

        console.log('result.data guardar :', result.data);

        if(result.data.Formulary[0]===0){
            toast.error('El formulario '+this.props.idForm+' no se pudo guardar ', {
                position: toast.POSITION.BOTTOM_CENTER
              });
        }else{
            toast.success('Se guardo correctamente el formulario', this.props.idForm, {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.props.toggle()
        }

    }

    
    render() {
        return (
        <div className="App">
        <MDBContainer>
        <MDBModal isOpen={this.props.modal} toggle={this.props.toggle} size="lg" >
            <MDBModalHeader toggle={this.props.toggle}>Formulario: {this.props.nameService}</MDBModalHeader>
            
                {this.state.loadingData?(
                <div>
                    <MDBIcon icon="cog" spin size="2x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />
                    <label>cargando....</label>
                </div>
                )
                :
                (
                    <MDBModalBody>
                <p className="h4 text-center mb-4">Enviar Formulario al Portal</p>
                <MDBRow>
                
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Id del Formulario de Softexpert
                            </label>
                            <input
                            type="text"
                            id="idForm"
                            name="idForm"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.idForm }
                            />
                            <br />
                </MDBCol>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Verison de Formulario
                            </label>
                            <input
                            type="text"
                            id="versionForm"
                            name="versionForm"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.versionForm }
                            />
                            <br />
                </MDBCol>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Id de Formulario
                            </label>
                            <input
                            type="text"
                            id="id"
                            name="id"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.id }
                            />
                            <br />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Id del servicio
                            </label>
                            <input
                            type="text"
                            id="idService"
                            name="idService"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.idService }
                            />
                            <br />
                </MDBCol>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Nombre del Servicio
                            </label>
                            <input
                            type="text"
                            id="nameService"
                            name="nameService"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.nameService }
                            />
                            <br />
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Id de la Institucion
                            </label>
                            <input
                            type="text"
                            id="idInstitution"
                            name="idInstitution"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.idInstitution }
                            />
                            <br />
                </MDBCol>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Nombre de la institucion
                            </label>
                            <input
                            type="text"
                            id="nameInstitution"
                            name="nameInstitution"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.nameInstitution }
                            />
                            <br />
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Imagen
                            </label>
                            <input
                            type="text"
                            id="url"
                            name="url"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.url }
                            />
                            <br />
                </MDBCol>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Entidad
                            </label>
                            <input
                            type="text"
                            id="entity"
                            name="entity"
                            className="form-control"
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.entity }
                            />
                            <br />
                </MDBCol>
                
            </MDBRow>


            <MDBRow>
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Creado
                            </label>
                            <input
                            type="text"
                            id="url"
                            name="url"
                            className="form-control"
                            disabled={true}
                            onChange={ e => this.handleInput(e)}
                            value={ this.state.created&& this.state.created.split('T')[0] +'-'+this.state.created.split('T')[1]}
                            />
                            <br />
                </MDBCol>
                <MDBCol end>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Validar formulario?
                            </label>
                            <select name="validate" className="form-control" onChange={ e => this.handleInput(e)} value={this.state.validate}>
                                <option value={1}>Si</option>
                                <option value={0}>No</option>
                            </select>
                            <br />
                </MDBCol>
                
            </MDBRow>
            </MDBModalBody>
                )
                }
            
            <MDBModalFooter>
                
                {this.state.loading&&(
                    <MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />
                )}
        
            <MDBBtn color="default"  onClick={()=> this.saveForm()}>Guardar</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        </MDBContainer>
        </div>
        );
    }
}

export default ModalUpdate;






