import React from 'react'
import { User } from "../../data/index";
import { 
    MDBIcon,  MDBContainer,
    MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact";
import { firebase } from "../../firebase";
import {  toast,ToastContainer } from 'react-toastify';
import { ErrorByFirebase } from "../../Helpers/helpers";

const { isAuth,logiForToken }  = User

export default function(ComposedComponent) {

  class RequireAuth extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isAuth: true,
            modal: false,
            loading: false,
            user: '',
            pass: ''
        }
        this.toggle = this.toggle.bind(this)      
        this.UserInput = this.UserInput.bind(this);
        this.PassInput = this.PassInput.bind(this);
    }

    // Push to login route if not authenticated on mount
    async componentWillMount() {
        const result = await isAuth()
      if(result.code === 401) {
       this.setState({
           modal: true
       })
      }
    }

    UserInput(e){
        this.setState({
          user: e.target.value
        })
      }
      PassInput(e){
        this.setState({
          pass: e.target.value
        })
      }
    toggle = () =>{

    }
    async Login(){
        this.setState({
          loading: true
        })
        const result = await firebase.auth()
        .signInWithEmailAndPassword(this.state.user, this.state.pass)
        .then((user)=>{
          return {'data': firebase.auth().currentUser, 'ok': true}
        })
        .catch((error)=> {
          console.log('error :', error.message);
          return {'data': error.code, 'ok': false}
        });
      
        if(result.ok){
          console.log(result)
          const token = await logiForToken(this.state.user, this.state.pass)
          localStorage.setItem('token-rules', token.data.token)
          toast.info('su sesion inicio nuevamente',{
            position: toast.POSITION.TOP_RIGHT
          })
          window.location.reload()
          
          this.setState({
            isAuth: false,
            modal: false
          })
        
        }else{
          console.log('result.data :', result.data);
          toast.error(ErrorByFirebase(result.data),{
            position: toast.POSITION.BOTTOM_CENTER
          })
        }
        this.setState({
          loading: false,
        })
      
      }

    logout(){
        firebase.auth().signOut().then((user)=>{
            this.setState({
                isAuth: false,
                modal: false
            })
            this.props.location.push('/home')
        })
        .catch((error)=> {
            this.setState({
                isAuth: true
            })
        });
      
      }

    // Otherwise render the original component
    render() {
        return (
            <div>
            <ToastContainer/>
            <ComposedComponent isAuth={this.state.isAuth} {...this.props}/>
            <MDBContainer>
        
            <MDBModal isOpen={this.state.modal} toggle={this.toggle}  size="md" >
                <MDBModalHeader >Su sesion se a cerrado por inactividad</MDBModalHeader>
                <MDBModalBody>
                <p className="h4 text-center mb-4">Vuelve a iniciar sesion</p>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Usuario
                            </label>
                            <input
                            type="email"
                            id="id_formulary"
                            name="id_formulary"
                            className="form-control"
                            onChange={ this.UserInput}
                            value={ this.state.user}
                            />
                            <br/>
                             <label htmlFor="defaultFormLoginEmailEx" >
                           Contraseña
                            </label>
                            <input
                            type="password"
                            id="id_fowrmulary"
                            name="id_fowrmulary"
                            className="form-control"
                            onChange={this.PassInput}
                            value={ this.state.pass}
                            />
                            
                           
                            
                            <br />
                </MDBModalBody>
                <MDBModalFooter>
                {this.state.loading&&(
                                <MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />
                            )}
                <MDBBtn color="default" size="lg" disabled={this.state.user ==='' || this.state.pass ===''? true: false} onClick={()=> this.Login()}>Entrar</MDBBtn>
                <MDBBtn color="red" size="lg" onClick={()=> this.logout()}>Salir</MDBBtn>
      
                </MDBModalFooter>
            </MDBModal>
            </MDBContainer>
            </div>)
    }

  }

  return RequireAuth

}