import React, { Component } from 'react';
import {  MDBRow, MDBCol,MDBCard, MDBIcon} from "mdbreact";
         
         import {  Form, } from "../../data/index";
         import Chart from './chart';
         import ChartsForm from './chartForm';

         const {GetAllFormularyDataTable} = Form

class Home extends Component {
  constructor(props){
    super(props);

    this.state = {
      bk: '',
      se: '',
      inst: 0,
      servicesTotal: 0,
      top5Services: [],
      lastServices: [],
      noData: false
    }
  }



  async componentDidMount(){
    let  services = 0
    let institutions = 0;
    let formSE = 0;
    let formBK = 0
    
    const result = await GetAllFormularyDataTable();
    if(result.data.Formulary){
      this.filterdata(result.data.Formulary)
      services = result.data.Formulary.length;
    }else{
      this.setState({
        noData: true
      })
    }

  }

  filterdata(data){

    this.setState({
      lastServices: data.slice(Math.max(data.length - 4, 1)),
      servicesTotal: data.length,
      bk:  this.filterForm(data)[0].length,
      se:  this.filterForm(data)[1].length,
      top5Services:  this.mostServicesForInst(data),
      inst:  this.filterInstitution(data).length
    })

    
  }

  filterInstitution(data){
    let result = []
    for (const key in data) {
      result.push(data[key].idinstitution)
    }
    return result.filter(function(item, index){
      return result.indexOf(item) >= index;
    });
  }

  filterForm(data){
    let se = []
    let bk = []
    for (const key in data) {
      if(data[key].provider==='bk'){
        bk.push(data[key])
      }else{
        se.push(data[key])
      }
      
    }
    return [bk,se]
  }


  mostServicesForInst(data){
    let inst = [];
    let allInst = []
    let labels = []
    let dataLenght = []

    for (const key in data) {
        inst[data[key].idinstitution] = []
    }

    for (const key in inst) {
      let result = inst[key]
      for (const i in data) {
        if (data[i].idinstitution == key) {
          
            result.push(data[i])
          
        }
      }
      
      allInst.push(result)
    }
   
   
    let arraySort = allInst.sort(function(a, b){  return a.length-b.length});
    let last5 =  arraySort.slice(Math.max(arraySort.length - 5, 1));

    for (const key in last5) {
      for (const i in last5[key]) {
        labels.push(last5[key][i].institution_name.split('-')[0])
      }
      dataLenght.push(last5[key].length)
    }
    labels = labels.filter(function(item, index){
      return labels.indexOf(item) >= index;
    });

    let result = [labels,dataLenght];

      return result

  }

  



  render() {
    console.log('env :', this.state);

    if(this.state.noData){
      return(
        <div>
            <h1>Usted no esta logueado</h1>
        </div>
      )
    }
    return (
      <div className="bg">
      <br/>
      <MDBRow>
      <MDBCol sm="6">
          
      <MDBCard className="padding" >
        <ChartsForm se={this.state.se || 1} bk={this.state.bk || 1}/>
      </MDBCard>
      </MDBCol>
      <MDBCol sm="6">
      <MDBCard >
      {this.state.lastServices.length<=0&&(
          <MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}} />

        )
      }
          <Chart services={this.state.top5Services}/>
      </MDBCard>
      </MDBCol>
      
  </MDBRow>
  <br/>
  <hr/>
  <br/>
 
  <MDBRow>
      <MDBCol>
        <MDBCard >
            
            <MDBRow around middle className="padding">
              <MDBIcon icon="building" className="indigo-text mr-4" size="10x"/>
              <label className="indigo-text" style={{ fontSize: 135}}>{this.state.inst}</label>
            </MDBRow>
            <label style={{ fontSize: 25}}>Total de instituciones</label>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard >
            <MDBRow around middle className="padding">
              <MDBIcon icon="hand-holding-usd" className="red-text mr-4" size="10x"/>
              <label className="red-text" style={{ fontSize: 135}}>{this.state.servicesTotal}</label>
            </MDBRow>
            <label style={{ fontSize: 25}}>Total de servicios</label>
        </MDBCard>
      </MDBCol>
      
  </MDBRow>

  
  

  <br/>
      <hr/>
      <br/>
      <h4>Ultimos servicios:</h4>
      <MDBRow>
      {this.state.lastServices.length<=0&&(
        <div className="col-md-12">
          <MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed /><br></br>
            <br></br>
            <b><h5>Cargando servicios...</h5></b>
        </div>
        )
      }
        {
          this.state.lastServices.length>0&&
        this.state.lastServices.map((service,i)=>(
          <MDBCol key={i}>
            <MDBCard className="padding">
              <center>
                  <img height="100" width="120" src={service.url? service.url: 'https://zenit.org/wp-content/uploads/2018/05/no-image-icon.png'}/><br/>
                  <label className="d-inline-block text-truncate" style={{maxWidth: "300px"}}>{service.service_name}</label>
              </center>
            </MDBCard>
          </MDBCol>
        ))}
          
      </MDBRow>
     

  <br/>
      <hr/>
      <br/>
      <MDBRow>
      <MDBCol>
          
      <MDBCard >
      
      </MDBCard>
      </MDBCol>
      
  </MDBRow>
    </div>
    );
  }
}

export default Home;