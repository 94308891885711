import React, { Component } from 'react'
import  CardText  from "../Utilities/CardText";
import {Softexpert, Mask,Backend} from '../../data/index';
import  FieldsLeft  from "../RenderField/FieldsLeft";
import {  toast,ToastContainer} from 'react-toastify';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import {formatedMask} from '../../Helpers/helpers'

import Select from 'react-select';
import maskJson from './maskJson'
const {GetForms} = Softexpert
const {GetFormularyByServiceBK} = Backend
const {GetMaskVersionById,saveMask,GetLatestMaskVersion} = Mask
export default class maskbuilder extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mask: [],
            result: [],
            lodading: false,
            field: null,
            fieldSave: [],
            msg:'flotante-label',
            maskJson: [],
            free: false,
            errors:{},
            errorMin: ""
        }

        this.infoField = this.infoField.bind(this)
    }

    async componentDidMount(){ 
         
        this.setState({
            lodading: true
        })
        const {location} = this.props
        const form = location.state.form
        const idMask = location.state.idMask
        const mask = await GetMaskVersionById(idMask)
        let result = [];
        let jsonMaks = [];
        let jsonForm = [];

        if(form.provider ==="se" || form.provider===null){
             result = await GetForms(form.idForm)
             console.log('reuslt from ******** ERROR*******', result)
             if(result.error){
                return this.setState({
                    errors: result
                })
             }
             let json = result.ok? result.data : []
             result = json
            
        }


        if(form.provider ==="bk"){
             result = await GetFormularyByServiceBK(form.idservice)
             let json = result.data.Form.success? result.data.Form.fields : []
             result = [JSON.parse(json)]
        }


        if(idMask === 'new'){
            this.setState({
                mask: [],
                result: result,
                lodading: false,    
                form: form, 
                fieldSave: [],
                maskJson:Object.values(maskJson),
                jsonObject: maskJson
            })
            return
        }

        if(mask.data.mask && !mask.data.mask.formatedFieldsMasked){
            jsonMaks = JSON.parse(mask.data.mask.fieldsMasked)
        }


        if(mask.data.mask && mask.data.mask.formatedFieldsMasked){
            for (let i = 0; i < result.length; i++) {
                for (let x = 0; x < result[i].length; x++) {
                    if(result[i][x].name){
                        jsonForm[result[i][x].name] = result[i][x];
                    }
                    
                    
                }
                
                
            }
            jsonMaks = formatedMask(mask.data.mask.formatedFieldsMasked,jsonForm,maskJson)
        }

        this.setState({
            mask: mask.data,
            result: result,
            lodading: false,    
            form: form,
            fieldSave: jsonMaks,
            maskJson:Object.values(maskJson),
            jsonObject: maskJson
        })
    }

    hiddenPage(id){
        let element = document.getElementById(id)
    
        if(element.style.display ==='none'){
            element.style='display:block'
        }else{
            element.style='display:none'
        } 
    
        
    }

    infoGridField(e,field,grid,nameGrid,setModal){
        let fieldsGrid = field.fields;
        let allFields = [];
        for (const i in fieldsGrid) {
            console.log('fieldsGrid', fieldsGrid[i])
            fieldsGrid[i].nameGrid = nameGrid
            allFields[fieldsGrid[i].name] = fieldsGrid[i]
        }
        field.fieldsGrid = allFields
        this.setState({
            field:field
        })
        setModal && setModal(false)
        console.log('field', field,grid,nameGrid)
    }

    infoField(e,field, grid, nameGrid,setModal){
        console.log('grid, nameGrid', grid, nameGrid)
        let selected = this.state.fieldSave[field.name]

        if(grid){
            return this.infoGridField(e,field, grid,nameGrid,setModal)
        }
        let fieldExample = {
            type: field.type,
            label: field.label,
            Mask: "",
            MaskParam: "",
            group: "grp01",
            orden: "100517100401",
            hidden: false,
            required: false,
            enabled: true,
            name: field.name,
            subtype: "text",
            length: 50,
            ruleIn: "",
            ruleOut: "",
        }



        this.setState({
            field:selected?selected:fieldExample
        })

    }


    changeValueGrid(e,field,fieldSave){
        let grid = fieldSave[field.nameGrid];

        if(!grid){
            let newField = []
            field[e.target.name] = e.target.value
            newField[field.name] = field
            fieldSave[field.nameGrid]  = newField
        }
        if(grid){
            field[e.target.name] = e.target.value
            grid[field.name]  = field
        }
        this.setState({
            field: field,
            fieldSave: fieldSave,
            free: false
        })
        
        
        
        

    }
    changeValue(e){
        let field = this.state.field
        let fieldSave = this.state.fieldSave
        let free = true
        console.log('e.target', e)
        if(field.grid){
            return this.changeValueGrid(e,field,fieldSave)
        }
        field[e.target.name] = e.target.value
        if(e.target.type ==='select-one' && e.target.name === 'Mask'){
            let label = e.target.label
            field[e.target.name] = {value: e.target.value, label: label}
            console.log('field', field)
        }
        if(e.target.type ==='select-one' && e.target.value === "-1"){
            field[e.target.name] = {value: "", label: ""}

        }
        if(e.target.type ==='select-one' && e.target.name === "valueDefault"  && e.target.value === "price"){
            field[e.target.name] = "price"
            delete field["quantity"];
            free = false
        }
        if(e.target.type ==='select-one' && e.target.name === "valueDefault"  && e.target.value === "quantity"){
            field[e.target.name] = "quantity"
            field["quantity"] = true
            free = false
        }
        if(e.target.type ==='select-one' && e.target.name === "valueDefault"  && e.target.value === "free"){
            field[e.target.name] = "free"
            free = true
        }
        if(e.target.type ==='select-one' && e.target.name === "valueDefault"  && e.target.value === "-1"){
            field[e.target.name] = ""
            free = false
        }
        if(e.target.name ==="min"){
            field["min"] =  parseInt(e.target.value)
            field["min"] = field["min"] === 0? null: field["min"]
            console.log('field["max"]', field["max"])
                // if(e.target.value > field["max"] && field["max"] !== 0){
                //     this.setState({
                //         errorMin: "El campo *DESDE* no pude ser menor al *HASTA*"
                //     })
                //     field["min"] = 0;
                // }
            if(e.target.value < field["max"]){
                this.setState({
                    errorMin: ""
                })
            }
        }
        if(e.target.name ==="max"){
            field["max"] = parseInt(e.target.value) 
            
           field["max"] = field["max"] === 0? null: field["max"]
          
        }

        if(field.nameGrid){
            fieldSave[field.nameGrid+'_'+field.name] = field  
        }
        if(!field.nameGrid){
            fieldSave[field.name] = field  
        }
        this.setState({
            field: field,
            fieldSave: fieldSave,
            free: free
        })
    }

    async saveAllMask(){
        let allMask = this.state.fieldSave;
        console.log('version', allMask);
        if(Object.keys(allMask).length>0){
            let json = JSON.stringify( Object.assign({}, allMask));
            let version = await GetLatestMaskVersion(this.state.form.id)
            let body = {
                fieldsMasked: json,
                idForm: this.state.form.id,
                maskVersion: version.data.mask + 1,
                formatedFieldsMasked:"",
                fieldLength:"",
                fieldsGridMasked:""
            }
            const result =  await saveMask(body);
            console.log('result', result)
            toast.success('Se creo otra version correctamente, version: '+(version.data.mask + 1), {
                position: toast.POSITION.BOTTOM_CENTER
              })
        }
        console.log('Object.keys(allMask)', Object.keys(allMask).length)

        if(Object.keys(allMask).length===0){
            toast.warn('No se ha cambiado ninguna configuracion en los campos', {
                position: toast.POSITION.BOTTOM_CENTER
              })
        }


    }

    clean(){
        let field = this.state.field
        field.Mask = {value: "-1", label: ""}
        field.MaskParam = ""
        field.length = ""
        field.valueDefault = "-1"
        delete this.state.fieldSave[field.name]
        this.setState({
            field:field
        })

    }

    label(value){
        this.setState({
            msg: value? 'flotante-label-hover' : 'flotante-label'
        })
    }

    valueDefault(e,name){
        let field = this.state.field;
        field.valueDefault = e.target.value;
        this.setState({
            field: field
        })
    }
  

    render() {
        console.log('this.state', this.state)
        return (
            <div>
                {this.state.lodading&&!this.state.errors.error&&(
                <MDBIcon icon="cog" size="2x" spin style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />

            )}
            <MDBRow>
            <ToastContainer/>
                <MDBCol size="9">
                    <MDBCol className="scrollingDivField">
                        <br/>
                        {this.state.errors.error&&(
                          <div>
                            <h1>{this.state.errors.error}</h1>
                            <h3>{this.state.errors.data}</h3>
                            <h6>{'CAMPO: '+this.state.errors.field.name +' - LABEL: '+this.state.errors.field.label}</h6>
                          </div>  
                        )}
                        {this.state.result.map((fields,i)=>(
                            <MDBRow key={i}>
                                <MDBCol  >
                                <div className="cursor" onClick={()=> this.hiddenPage("FromPage"+i)}>
                                <CardText  color="#00695c" text={"Pagina: "+ (i+1)}/>
                                </div>
                                <div id={"FromPage"+i}>
                                    <FieldsLeft key={i} onlyText={true} field={[fields,fields]} fieldSave={this.state.fieldSave} FromInput={this.infoField } FieldFromInput={this.state.field} />
                                </div>
                                </MDBCol>
                                <hr/>
                            </MDBRow>
                        ))}
                        </MDBCol>
                    </MDBCol>
                <MDBCol size="3" className="border scrollingDivField">
                <h4>Informacion de campo</h4>
                <hr/>
                {this.state.field?(
                    <div>
                        <label>Nombre:</label>
                        <input className="form-control inputMask" value={this.state.field.name} disabled/><br/>
                        <label>Tipo:</label>
                        <input className="form-control inputMask" value={this.state.field.type} disabled/><br/>
                        <label>Titulo:</label>
                        <input className="form-control inputMask" value={this.state.field.label} disabled/><br/>
                        {this.state.field&&this.state.field.grid&&(
                        <div>
                            <label>Id del grid:</label>
                            <input className="form-control inputMask" value={this.state.field&&this.state.field.nameGrid} disabled/><br/>
                        </div>
                        )}
                       
                        <label>Mascara:</label>
                        <Select
                             name="Mask"
                             value={this.state.field.Mask&&{mask: this.state.field.Mask.value, label: this.state.field.Mask.label}}
                             onChange={e => this.changeValue({target:{name: 'Mask', value: e.mask,label: e.label,type:'select-one'}})}
                             options={ this.state.maskJson}
                             placeholder="seleccione una mascara"
                              /><br/>

                       {this.state.field.Mask.value !== "6"&&(
                           <div>
                            <label>Parametro de Mascara:</label>
                            <input className="form-control inputMask" name="MaskParam" value={this.state.field.MaskParam} onChange={e => this.changeValue(e)} /><br/>
                           </div>
                       )}

                       {this.state.field.Mask.value === "6"&&(
                           <div>
                            <label>DESDE:</label>{this.state.errorMin&&(<label style={{color: 'red', fontSize: 10}}>{this.state.errorMin}</label>)}

                            <input type="number" className="form-control inputMask" name="min" value={this.state.field.min} onChange={e => this.changeValue(e)} /><br/>
                            <label>HASTA:</label>
                            <input type="number" className="form-control inputMask" name="max" value={this.state.field.max} onChange={e => this.changeValue(e)} /><br/>
                           </div>
                       )}

                        <label>cantidad de caracteres:</label>
                        <input className="form-control inputMask" name="length" value={this.state.field.length} onChange={e => this.changeValue(e)}/><br/>
                        <label>Valor por defecto:</label>
                        <select className="form-control inputMask" name="valueDefault" value={this.state.field.valueDefault} onChange={e => this.changeValue(e)}>
                            <option value="-1">Seleccione una opción</option>
                            <option value="price">1- Precio del servicio</option>
                            <option value="quantity">2- Cantidad seleccionada en el precio</option>
                            <option value="free">4- Valor libre</option>
                        </select><br/>
                        
                       {this.state.free&& this.state.field.valueDefault !== ''&&(
                        <div>
                            <label>Agregue el valor:</label>
                            <input className="form-control inputMask" value={this.state.field.valueDefault} onChange={(e)=> this.valueDefault(e)}/>
                        </div> )}
                    </div>
                ):(
                    <label>No hay campo seleccionado</label>
                )}

                <button  className={"btn-float flotante "+ process.env.REACT_APP_EXECUTION_ENV==='production'? process.env.REACT_APP_COLOR_PRODUCTION: process.env.REACT_APP_COLOR_DEVELOPMENT} onMouseOver={()=> this.label(true)} onMouseOut={()=> this.label(false)} onClick={()=> this.saveAllMask()} top>
                    <MDBIcon icon="save"  onMouseOver={()=> this.label(true)} onMouseOut={()=> this.label(false)} size="lg" className="text-white cursor"/>
                </button>

                <label  className={" btn-label " + this.state.msg}>Guardar Reglas</label>

                    
                </MDBCol>
            </MDBRow>
            </div>
        )
    }
}
