import React, { Component } from 'react';
import {MDBRow,MDBCol} from "mdbreact";
import { TFile,
    THeaders,
    TRadio,
    TSelect,
    TSubTitle,
    TText,
    TTextarea,
    TTime,
    TDate,
    TGrid,
    TCheckbox } from "../form/index";



class Fields extends Component {
  constructor(props){
    super(props)
    this.state = {
      selected: true,
      class: 'padding ',
      rule: this.props.rule,
      Resumen:  [] ,
      selectedKeys: []
    }
  }
     
  render() {
    const field = this.props.field
    return (
        <div >
        {
            
            field.type === 'text' ? (
                <TText    id={ field.label}  field={field}/>
                   
            )
            :
            field.type === 'radio-group' ? (
                <TRadio   id={ field.label} field={field}/>
             
            )
            :
            field.type === 'select'   ? (
                <TSelect  id={ field.label} field={field}/>
                
            )
            :
            field.type === 'file' ? (
                <TFile   id={ field.label} field={field}/>

            )
            :
            field.type === 'header' ? (
                
               
                <THeaders   id={field.label}   field={field}/>
               
            )
            :
            field.type === 'textarea' ? (
                <TTextarea  id={ field.label}  field={field}/>

            )
            :
            field.type === 'time' ? (

                <TTime  id={ field.label} field={field}/>
    
            )
            :
            field.type === 'date' ? (
                <TDate id={field.label}  field={field}/>
            
            )
            :
            field.type === 'grid' ? (
                <TGrid config={true} arrayGrid={this.props.arrayGrid} json={this.props.json} selectedCheck={this.props.selectedCheck} create={this.props.create} id={field.name}  field={field}/>
            
            )
            :
            field.type === 'subtitle' ? (
                <TSubTitle  id={ field.label} field={field}/>
            
            ):
            field.type === 'checkbox-group' && (
                <TCheckbox   id={ field.label}  field={field}/>
            
                )
            }
    </div>
    );
  }
}
export default Fields;