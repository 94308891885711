import React, { Component } from 'react';
import { MDBDataTable,MDBIcon,MDBModal,
MDBModalBody, MDBModalHeader,MDBBtn,MDBTable, 
MDBTableBody, MDBTableHead} from "mdbreact";
import {  ToastContainer } from 'react-toastify';

import { Form, User,Roles,Router } from "../../data/index";


const {GetAllFormularyDataTable } = Form
const {allUsers,} = User
const { saveRol, deleteRolesById,GetRolesByUser} = Roles
const {saveRouter,getRouterByUser,deleteRouter} = Router
class Users extends Component {
    constructor(props){
        super(props)
        this.state = {
            AllUsers: [],
            loading: false,
            modal: false,
            modalRouter: false,
            idUser:'',
            AllForm: [],
            roles: []
        }
        
        this.toggle = this.toggle.bind(this);
        this.toggleRouter = this.toggleRouter.bind(this);
    }

    async toggle(id, name, last){
        const result =  await GetRolesByUser(id)
        if(!this.state.modal){
            this.showDataTableForm();
        }
        this.setState({
            modal: !this.state.modal,
            name: name + ' '+last,
            idUser:id,
            roles:result.data.rol
        })
       

        
    }


    async toggleRouter(id, name, last){
        const result = await getRouterByUser(id)
        this.setState({
            modalRouter: !this.state.modalRouter,
            name: name + ' '+last,
            idUser:id
        })

        if(result.data.routers.length>0){
            await this.sleep(1)
            let data = result.data.routers;
            for (const key in data) {
                document.getElementById(data[key].router).checked = true;
            }
        }
        
    }

  
    componentDidMount(){
       
        this.showDataTable();
    }



    async showDataTable(){
                this.setState({
                    loading: true
                    })
                const users = await allUsers();
                let data = users.data
                let array = []
                try{
                    for (const i of data) {
                    let element = {
                        id: i.id,
                        Nombre: i.firstname,
                        Apellido: i.lastname,
                        Correo: i.email,
                        Accion: [
                            <MDBBtn color="primary" className="btn-sm" onClick={ e =>this.toggle(i.id,i.firstname,i.lastname)}>Formularios</MDBBtn>,
                            <MDBBtn color="success" className="btn-sm" onClick={ e =>this.toggleRouter(i.id,i.firstname,i.lastname)}>Rutas</MDBBtn>
                        ]
                    }
                    array.push(element)
                    }
                }catch(e){console.log(e)}
                const column = [
                {label:'id',field:'id',sort:'asc'},
                {label:'Nombre',field:'Nombre',sort:'asc'},
                {label:'Apellido',field:'Apellido',sort:'asc'},
                {label:'Correo',field:'Correo',sort:'asc'},
                {label:'Accion',field:'Accion',sort:'asc'}
                ]
                const result = {columns:column, rows: array}
                this.setState({
                AllUsers: result,
                loading: false
                })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    async checkPermision(roles,id){
            for (let key in roles) {
                if(parseInt(roles[key].idForm) === id){
                    return true
                }

            } 
            return false
    }

    async showDataTableForm(filter,data){
        if(!filter){
            this.setState({
                loading: true
                })
            data = await GetAllFormularyDataTable();
        }
        let array = []
        try{
            for (const i of data.data.Formulary) {
            let checked = await this.checkPermision(this.state.roles, i.id)
            let element = {
                id: i.id,
                idForm: i.idForm,
                service: filter?i.service:i.service_name,
                institution: filter?i.institution:i.institution_name,
                Permiso: <input  id={'check'+i.id} checked={checked} type="checkbox" onClick={(e)=>this.permission(e,i.id)}/>
            }
            array.push(element)
            }
        }catch(e){console.log(e)}
        const column = [
            {label:'id',field:'id',sort:'asc'},
            {label:'formulario',field:'idForm',sort:'asc'},
            {label:'Servicio',field:'service',sort:'asc'},
            {label:'Institucion',field:'institution',sort:'asc'},
            {label:'Accion',field:'Permiso',sort:'asc'},
          ]
        const result = {columns:column, rows: array}
        
        this.setState({
        AllForm: result,
        loading: false
        })
        
}


    async permission(e,idForm){
        let user = this.state.idUser;
        let body = {
            idUser: user,
            idForm: idForm
        }
        if(e.target.checked){
            const rol = await saveRol(body);
            document.getElementById('check'+idForm).checked = true
        }else{
            const result = await deleteRolesById(user,idForm)
            document.getElementById('check'+idForm).checked = false
        }
    }




    async setRouter(e){
        let element = e.target;

        let body = {
            router: element.id,
            idUser: this.state.idUser
        }
        if(element.checked){
            const result = await saveRouter(body)
        }else{
            const remove = await deleteRouter(body.idUser, body.router)
        }
    }


    search(e){
    let value = e.target.value;
    let data = []; 
    let result  = [];

    if(this.state.AllForm){
        data = this.state.AllForm.rows
    }else{

         
        this.showDataTableForm()
        
        return
    }

    if(value.length<3 || !value || this.state.loading){
        return
    }
     result = data?data.filter(function (form,n){
        return form.idForm.toLowerCase().search(value.toLowerCase()) !== -1;
    }):[];

    

    if(result.length===0){
        result = data?data.filter(function (form,n){
            return form.institution.toLowerCase().search(value.toLowerCase()) !== -1;
        }):[];
    }
    
    if(result.length===0){
        result = data?data.filter(function (form,n){
            return form.service.toLowerCase().search(value.toLowerCase()) !== -1;
        }):[];
    }


    this.setState({
        AllForm: result.length === 0 ? data : {columns: this.state.AllForm.columns, rows:result}
    })

    if(!value){
        this.showDataTableForm()
    }
    

    }



    filterForms(value){
        if(!value) return this.showDataTableForm()
        let forms = this.state.AllForm;
        let filter =  forms.rows.filter(function(form) {
            return form.idForm.toLowerCase().search(value) !== -1
        });
        this.showDataTableForm(true,{data: {Formulary: filter}})
        let result =  {columns: forms.columns, rows:filter}
        // this.setState({
        //     AllForm: result
        // })


    }




    render() {
        return (
        <div className="App">
        <ToastContainer/>
        <br/><br/>
        <h1>Users</h1>
        <h5>Ambiente: {process.env.REACT_APP_EXECUTION_ENV}</h5>
        
        <MDBDataTable
            striped
            bordered
            hover
            data={this.state.AllUsers}
        />
                
            {/** =================== MODAL =================== */}
            
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="fluid" >
            <MDBModalHeader toggle={this.toggle}>Asignar permisos a <b>{this.state.name}</b></MDBModalHeader>
            <MDBModalBody>
            {this.state.loading? (<MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />):''}
                {/**    
                <input type="search" className="form-control" placeholder="buscar....." onChange={(e)=>{this.search(e)}}/><br/>
                 */}
            {!this.state.loading&&(
                <div>
                <label>Buscar un formulario</label><br/>
                 <input type="search" className="form-control" placeholder="buscar un formulario por id o servicio" onChange={e => this.filterForms(e.target.value)} style={{position: 'absolute',width: 400, right: '1%', zIndex: 1}}/>
                <MDBDataTable
                    striped
                    bordered
                    hover
                    paging={true}
                    searching={false}
                    data={this.state.AllForm}
                />
                </div>
            )}
            
            
            </MDBModalBody>
        </MDBModal>






             
            {/** =================== MODAL ROUTER =================== */}
            
            <MDBModal isOpen={this.state.modalRouter} toggle={this.toggleRouter} size="lg" >
                <MDBModalHeader toggle={this.toggleRouter}>Asignar rutas a <b>{this.state.name}</b></MDBModalHeader>
                <MDBModalBody>
                {this.state.loading? (<MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />):''}
                <MDBTable striped>
                    <MDBTableHead>
                        <tr>
                            <th>#</th>
                            <th>Ruta</th>
                            <th>Permiso</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <tr>
                            <td>1</td>
                            <td>Tablero</td>
                            <td><input onClick={e =>this.setRouter(e)} type="checkbox" id="dash"/></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Reglas</td>
                            <td><input onClick={e =>this.setRouter(e)} type="checkbox" id="rule"/></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Mascaras</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="mask"/></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Integracion Qr</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="qr"/></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Envio y creacion Formularios</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="form"/></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Transferencia</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="tranfer"/></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Administrador de formularios</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="admin"/></td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Administrador de usuarios</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="users"/></td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Reglas de precio</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="rulesprice"/></td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Configuracion de campos</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="config"/></td>
                        </tr>
                    </MDBTableBody>
                    </MDBTable>
                </MDBModalBody>
            </MDBModal>



        {this.state.loading? (<MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />):''}
        </div>
        );
    }
    }

export default Users;
