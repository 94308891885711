import React, { Component } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead
    ,MDBModal,MDBModalHeader,MDBModalBody, MDBContainer  } from "mdbreact";
import 'react-toastify/dist/ReactToastify.css';

class ModalFieldGroup extends Component {
constructor(props){
        super(props)
        this.state = {
            data: [],
            modalLoading: false,
            modal: false,
        }


    }

    groupValue(e,group, data,option){
        
        for (let i = 0; i < data.length; i++) {
            let fields = data[i][group];
            if(data[i][group]){
                for (const i in fields) {
                    this.props.create(e,fields[i].name,fields[i].type,option, true, group )
                }
            }
            
        }
        



    }

    fsValue(e,group, data,option){
        let fields = data[group];

        for (const i in fields) {
            this.props.create(e,fields[i].name,fields[i].type,option, true, group )
        }



    }
    setValueGroup(e,option){
        let name = this.props.groupSelected;
        let pages = this.props.lvl
        for (const i in pages) {
            let group = Object.keys(pages[i])
            let datagrp = Object.values(pages[i])
            
            for (const i in group) {
                console.log('es un grupo', name,  Object.values(pages),group)
                if(group[i] == name){

                    console.log('es un grupo', name,  Object.values(pages),option)
                    this.groupValue(e,group[i],  Object.values(pages),option)
                }
            }

            for (const i in datagrp) {
                let fs = Object.keys(datagrp[i])
                Object.values(datagrp)
                for (const key in fs) {
                    if(fs[key] == name){
                        this.fsValue(e,fs[key],  datagrp[i],option)
                    }
                }
               
                
            }
            
        }
    }




    
    

  render() {
      console.log('this.props', this.props)
     return (
     <div className="App padding">

    {/**===============================================MODAL================================================= */}
    <MDBContainer>
    <MDBModal isOpen={this.props.modal} toggle={ ()=>this.props.toggle()} size="fluid">
      <MDBModalHeader toggle={()=>this.props.toggle()}>Accione reglas para el grupo: <b>{this.props.name}</b></MDBModalHeader>
      <MDBModalBody id="modal-body-card-form">
      <MDBTable  bordered>
                <MDBTableHead>
                    <tr>
                        <th>Requerido</th>
                        <th>No Requerido</th>
                        <th>Oculto</th>
                        <th>Visualziar</th>
                        <th>Deshabilitado</th>
                        <th>Habilitar</th>
                        <th>CLick</th>
                        <th>Limpiar</th>
                        <th>Cambiar a Cedula</th>
                        <th>Cambiar a Pasaporte</th>
                        <th>Cambiar a RNC</th>
                        <th>API - Portal</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    
                        <tr>
                         
                            <td>
                                <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['notRequired'] || this.props.groupActived[ this.props.groupSelected]['hidden'] || this.props.groupActived[ this.props.groupSelected]['disabled'] ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    onClick={e => this.setValueGroup(e, 'required')}
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['required']}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['required']  ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    onClick={e => this.setValueGroup(e, 'notRequired')}
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['notRequired']}

                                   />
                                </center>
                            </td>
                            

                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['required'] || this.props.groupActived[ this.props.groupSelected]['show'] || this.props.groupActived[ this.props.groupSelected]['active']   ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['hidden']}
                                    onClick={e => this.setValueGroup(e, 'hidden')}
                                   />
                                </center>
                            </td>

                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['hidden']   ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['show']}
                                    onClick={e => this.setValueGroup(e, 'show')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['active'] || this.props.groupActived[ this.props.groupSelected]['required']    ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['disabled']}
                                    onClick={e => this.setValueGroup(e, 'disabled')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['disabled']  || this.props.groupActived[ this.props.groupSelected]['hidden']   ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['active']}
                                    onClick={e => this.setValueGroup(e, 'active')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['click']}
                                    onClick={e => this.setValueGroup(e, 'click')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['clean']}
                                    onClick={e => this.setValueGroup(e, 'clean')}
                                   />
                                </center>
                            </td>
                            <td>
                            
                            
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['rnc']  || this.props.groupActived[ this.props.groupSelected]['pasaporte']    ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['cedu;a']}
                                    onClick={e => this.setValueGroup(e, 'cedula')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['cedula']  || this.props.groupActived[ this.props.groupSelected]['rnc']    ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['pasaporte']}
                                    onClick={e => this.setValueGroup(e, 'pasaporte')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    disabled={
                                        this.props.groupActived[ this.props.groupSelected]? 
                                        this.props.groupActived[ this.props.groupSelected]['cedula']  || this.props.groupActived[ this.props.groupSelected]['pasaporte']    ? 
                                                true
                                                : 
                                                false 
                                        : 
                                        false
                                    }
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['rnc']}
                                    onClick={e => this.setValueGroup(e, 'rnc')}
                                   />
                                </center>
                            </td>
                            <td>
                            <center className="form-group">
                                    <input
                                    type="checkbox"
                                    checked={this.props.groupActived[ this.props.groupSelected]&&this.props.groupActived[ this.props.groupSelected]['api']}
                                    onClick={e => this.setValueGroup(e, 'api')}
                                   />
                                </center>
                            </td>
                            
                        </tr>
               
                
                    </MDBTableBody>
                    </MDBTable>
      </MDBModalBody>
    </MDBModal>
  </MDBContainer>
    </div>
    );
  }
}
export default ModalFieldGroup;