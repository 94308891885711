
import axios from "axios";

function axios_api(api) {
    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
   return axios
  }




    function saveRules(body) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/rule').post(process.env.REACT_APP_API+'/rule',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }

    function GetRulesById(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/rule/'+id).get(process.env.REACT_APP_API+'/rule/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }

    function GetRulesByForm(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleByForm/'+id).get(process.env.REACT_APP_API+'/ruleByForm/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }

    function deleteRulesByForm(id) {

        const result =  axios_api(process.env.REACT_APP_API+'/rule/'+id).delete(process.env.REACT_APP_API+'/rule/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }


    function deleteRulesById(id) {

        const result =  axios_api(process.env.REACT_APP_API+'/rule/one/'+id).delete(process.env.REACT_APP_API+'/rule/one/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }


    function GetLastversionRule(id) {
        const result = axios_api(process.env.REACT_APP_API+'/lastversion/'+id).get(process.env.REACT_APP_API+'/lastversion/'+id).then((res)=>{    
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
    }


    export {
        GetLastversionRule,
        GetRulesByForm,
        deleteRulesByForm,
        saveRules,
        GetRulesById,
        deleteRulesById
        
    }
