import React, { Component } from 'react';
import {MDBRow,MDBCol} from "mdbreact";
import {
    FCheckbox,
    FRadio,
    FSelect,
    FText,
    FDate,
    FFile,
    FTime,
    Ftextarea,
    FHeader,
    TGrid
 } from "../form/index";
class FieldsLeft extends Component {
  constructor(props){
    super(props)
    this.state = {
      selected: true,
      class: 'padding ',
      rule: this.props.rule,
      Resumen:  [] 
    }
  }

  
  render() {
    const FromField = this.props.field[0]
    console.log('this.props.field :', this.props.field);
    return (
        
        <MDBRow >
        {FromField.map((field, i)=>(

                field.type === 'radio-group' && !this.props.onlyText ? (
                    <MDBCol size="6"  className="cursor">
                        <FRadio key={i} id={i + field.label} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput}  field={field}/>
                    </MDBCol>
                )
                :
                (field.type === 'select' && !field.data_portal &&   !this.props.onlyText) ? (
                    <MDBCol size="6" className="cursor" >
                        <FSelect key={i} id={i + field.label} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                )
                :
                field.type === 'text' ? (
                    <MDBCol size="6" className="cursor" >
                        <FText key={i} id={i + field.label} fieldSave={this.props.fieldSave} textForMask={this.props.onlyText} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                ):
                field.type === 'header' ? (
                
                    <MDBCol size="12" className="cursor">
                    <hr/>
                    <FHeader  key={i} id={i + field.label}  field={field}/>
                    </MDBCol>
                )
                :
                field.type === 'date' ? (
                    <MDBCol size="6" className="cursor" >
                        {this.props.onlyText?(
                         <FText key={i} id={i + field.label} fieldSave={this.props.fieldSave} textForMask={this.props.onlyText} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>

                        ):(
                         <FDate key={i} id={i + field.label}  handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                        )}
                    </MDBCol>
                )

                :
                field.type === 'time' ? (
                    <MDBCol size="6" className="cursor" >
                    {this.props.onlyText?(
                        <FText key={i} id={i + field.label} fieldSave={this.props.fieldSave} textForMask={this.props.onlyText} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>

                    ):(
                        <FDate key={i} id={i + field.label}  handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    )}
                    </MDBCol>
                )


                :
                field.type === 'file'  && !this.props.onlyText ? (
                    <MDBCol size="6" className="cursor" >
                        <FFile key={i} id={i + field.label} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                )

                :
                field.type === 'textarea' ? (
                    <MDBCol size="6" className="cursor" >
                        <Ftextarea key={i} id={i + field.label} textForMask={this.props.onlyText} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                )
                :
                field.type === 'grid' ? (
                    <MDBCol size="12" className="cursor"  >
                        <TGrid selectableKey={field.name} onlyText={this.props.onlyText} fieldSave={this.props.fieldSave}  key={i} id={field.name} ToInput={this.props.FromInput} fieldToInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                )
                
                :
                field.type === 'checkbox-group' &&   !this.props.onlyText && (
                    <MDBCol size="6" className="cursor" >
                        <FCheckbox key={i} id={i + field.label} handlerFromInput={this.props.FromInput} fieldFromInput={this.props.FieldFromInput} field={field}/>
                    </MDBCol>
                )
                    
            
        ))}
    </MDBRow>
    );
  }

 


 
}

export default FieldsLeft;