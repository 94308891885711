import React, { Component } from 'react';
export default class TText extends Component {
    componentWillReceiveProps(props){
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group">
          <label><b>{field.label}</b> <span id={'rule'+field.name}></span></label>
          <input
            type="text"
            className="form-control"
            id={i}
            key={i}
            name={field.name}
            placeholder={'id: '+ (field.name)}
            disabled="disabled"
          />
        </div>
      );
    }
  }
  
