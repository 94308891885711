
import axios from "axios";


    function axios_api(api) {
    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
    return axios
    }


    function logiForToken(email,pass) {
        let body = {
        email:email,
        password: pass
        }
        const result =  axios_api(process.env.REACT_APP_API+'/login').post(process.env.REACT_APP_API+'/login/',body).then((res)=>{ 
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        
        return result;
    }



    function isAuth() {
        const result =  axios_api(process.env.REACT_APP_API+'/isAuth').get(process.env.REACT_APP_API+'/isAuth').then((res)=>{
        return {'data':res.data,'ok':true, 'code': res.status};
        }).catch((error)=>{
        return {'data':error,'ok':false, 'code':  error.response.status};
        });
        return result;
        
    }


    function allUsers() {
        const result =  axios_api(process.env.REACT_APP_API+'/formulary/allusers').get(process.env.REACT_APP_API+'/formulary/allusers').then((res)=>{
        return {'data':res.data,'ok':true, 'code': res.status};
        }).catch((error)=>{
        return {'data':error,'ok':false, 'code':  error.response};
        });
        return result;
        
    }



    export {
        isAuth,
        logiForToken,
        allUsers
    }