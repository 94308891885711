import React, { Component } from 'react';
export class MaskSelectOption extends Component {
    constructor(props){
        super(props)
    }
    render() {
      return (
          <div className="form-group" >
            <select className="form-control mt-3"  onChange={this.props.mask} value={this.props.value}>
                <option value="-1"  disabled>Seleccione una opción</option>
                <option value="0"> 0 - CEDULA</option>
                <option value="1"> 1 - RNC</option>
                <option value="2"> 2 - TELÉFONO</option>
                <option value="3"> 3 - CELULAR</option>
                <option value="4"> 4 - CODIA</option>
                <option value="5"> 5 - EMAIL</option>
                <option value="6"> 6 - SOLO NUMERO</option>
                <option value="7"> 7- SOLO LETRA</option>
                <option value="8"> 8 - INMUEBLE</option>
                <option value="9"> 9 - COD.SISTEMA CAASD</option>
                <option value="10"> 10 - FECHA</option>
                <option value="11">11 - HORA</option>
                <option value="12">12 - DECIMAL</option>
                <option value="13">13 - EXPEDIENTE CAID</option>
                <option value="14">14 - NSS INABIMA</option>
                <option value="15">15 - CARGA EDESUR</option>
                <option value="16"> 16 - MONTO INABIMA</option>
                <option value="17">17 - LATITUD</option>
                <option value="18">18 - LONGITUD</option>
                <option value="19">19 - MAYUSCULAS</option>
                <option value="20">20 - CÉDULA INABIMA</option>
                <option value="21">21 - EXPEDIENTE CAID</option>
                <option value="22">22 - FECHA NO MAYOR ACTUAL</option>
                <option value="23">23 - FECHA NO MENOR ACTUAL</option>
                <option value="24">24 - EDAD NO MAYOR A LA ENVIADA</option>
                <option value="25">25 - FECHA NO MENOR ESPECIFICADA</option>
                <option value="26">26 - FECHA DIA LABORABLE</option>
                <option value="27"> 27- Monto general No negativo</option>
                <option value="28">28 - Entero no negativo</option>
                <option value="29">29 - Fecha de Vuelo Menor a 48 Horas</option>
                <option value="30">30 - Fecha de Vuelo Menor a 48 Horas(Otro ID)</option>
                <option value="31">31 - Fecha de Embarque</option>
                <option value="32">32 - Validacion de Maestro y Solicitud Abierta</option>
                <option value="33">33 - VALIDAR DECIMAL ENTRE 0 - 100</option>
                <option value="34">34 - CODIGO INMUEBLE</option>
                <option value="35">35 - PLACA</option>
                <option value="36">36 - Validacion INVI</option>
                <option value="37">37 - Validacion Junta de Retiro FFAA</option>
            </select>
        </div>
      );
    }
  }
