import { Route, Redirect } from "react-router-dom";
import React from "react";


const auth = () => {
    const token = localStorage.getItem('token-rules')
    return token ? true : false
}

const PrivateRoute = (props) => (
    auth()
        ? <Route {...props} />
        : <Redirect to="/login" />
)

export default PrivateRoute