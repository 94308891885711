
import axios from "axios";
function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
 return axios
}


    function saveRouter(body) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/router').post(process.env.REACT_APP_API+'/router',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }



    function getRouterByUser(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/router/'+id).get(process.env.REACT_APP_API+'/router/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }


    function deleteRouter(id,router) {
        const result =  axios_api(process.env.REACT_APP_API+'/router/'+id+'/'+router).delete(process.env.REACT_APP_API+'/router/'+id+'/'+router).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }




    export {
        saveRouter,
        getRouterByUser,
        deleteRouter
    }