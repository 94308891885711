import React, { Component } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead,MDBRow, MDBCol,MDBIcon,MDBModalFooter,MDBBtn
,MDBContainer,MDBModal,MDBModalHeader,MDBModalBody, MDBCard,MDBInput   } from "mdbreact";

import {RulesInit} from "../../data/index";
import {toast,ToastContainer } from 'react-toastify';

const {saveRulesInit,GetRulesInitByForm,updateRulesInit, GetRulesInitById, setRulesInitSelected, GetRulesInitSelected } = RulesInit
class BtnImportRule extends Component {


    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false,
            modal: false,
            rules:[],
            update: false,
            idRuleInit:0,
            AllRules: [],
            modalAllRules: false
        }
    }


    async AllData(){
        const result = await GetRulesInitByForm(this.props.idForm);
        
        if(result.data.rules){
            this.setState({
            AllRules: result.data.rules,
            idRuleInit:result.data.rules.id
            })
        }
    }

     componentDidMount(){
        this.AllData()
        
    }


    async toggleAllRules(){
        this.AllData()
        this.setState({
            modalAllRules: !this.state.modalAllRules
        })
        setTimeout(await this.checkSelected(), 2000);
    }


    async checkSelected(){
        const selected = await GetRulesInitSelected(this.props.idForm)
        if(selected.data.rules){
            let check =   document.getElementById(selected.data.rules.id+'check')
            check&&(check.checked = true)
        }
    }



    async  toggle(id){

        
      this.setState({
        modal: !this.state.modal
      })
      const result = await GetRulesInitById(id);
      console.log('@@@@#######result :', result);
      let ruleinit = [];
      if(result.data.rules){
          ruleinit = JSON.parse(result.data.rules.rule);
          this.setState({rules:ruleinit})
          for (const key in ruleinit) {
              
              if(ruleinit[key].type==='select'){
                  console.log('object :', ruleinit[key].name);
                let elementSelect =  document.getElementById(ruleinit[key].name+'select')
                elementSelect&&(elementSelect.checked = true)
                let element =  document.getElementById(ruleinit[key].name);
                console.log('element :', element);
                if(element){
                    for (const i in element.options) {
                        if(element.options[i].value === ruleinit[key].value){
                            element.options[i].selected = true
                            // let check = document.getElementById(ruleinit[key].name+'select').checked = true;
                            
                        }
                    }
                }
                
              }else if(ruleinit[key].type==='radio-group'){

                let elementRadio =  document.getElementById(ruleinit[key].name+'radio')
                    elementRadio&& (elementRadio.checked = true)
                let elementCheckRadio =  document.getElementById(ruleinit[key].name+ruleinit[key].value)
                    elementCheckRadio&& (elementCheckRadio.checked = true)

              }else if(ruleinit[key].type==='text'){

                let elementText =  document.getElementById(ruleinit[key].name)
                    elementText&& (elementText.value = ruleinit[key].value)
                let elementCheckRadio =  document.getElementById(ruleinit[key].name+'text')
                console.log('elementCheckRadio', elementCheckRadio)
                    elementCheckRadio&& (elementCheckRadio.checked = true)
                

              }else{
                let elementCheck =  document.getElementById(ruleinit[key].name+'check')
                    elementCheck&& (elementCheck.checked = true)
                let elementCheckCheck =   document.getElementById(ruleinit[key].name)
                    elementCheckCheck&& (elementCheckCheck.checked = true)
              }
              
          }
      }
      console.log('eto!:',ruleinit );
      //const form;
    }

   async  initField(e,name,type) {
       if(e.target.checked === false){
        this.deleteRule(name,e.target.id)
        return
       }
        let element = '';
        if(type==='select'){
            element =  document.getElementById(name);
        }else if(type==='radio-group'){
            element =  document.querySelector('input[name="'+name+'"]:checked')
        }else{
            console.log('element :', element);
            element =  document.getElementById(name);
        }

        if(element){
            this.setState({
                rules: this.state.rules.concat( {
                     name: name,value: element.value, type: type
                    })
                })
                console.log('this.state.rules :', this.state.rules);
        }else{
            toast.info('Tienes que seleccionar un valor antes de inicializar este campo',{
                position: toast.POSITION.BOTTOM_CENTER
            })
            e.target.checked = false
        }
            
    }

    deleteRule(name,checkName){
    //    let check =  document.getElementById(checkName).checked=false
       let arrayRules = this.state.rules
       if(arrayRules.length>0){
           for (const key in arrayRules) {
            if(name ===arrayRules[key].name){
                 arrayRules.splice(key, 1);
            }
           }
       }
    }

   async  saveRuleInit(){
        let arrayRules = JSON.stringify(this.state.rules);
        let body = {
            rule: arrayRules,
            idForm:this.props.idForm,
            selected: 0
        }
        const result = await saveRulesInit(body);
        console.log('arrayRules :', result);
        if(result.data.rulesinit){
            toast.success('Se guardo la configuracion para inicializar',{
                position: toast.POSITION.BOTTOM_CENTER
            })
        }else{
            toast.error('Error al guardar la configuracion para inicializar',{
                position: toast.POSITION.BOTTOM_CENTER
            })
        }
    }
    async updateRuleInit(){
        let arrayRules = JSON.stringify(this.state.rules);
        let body = {
            rule: arrayRules,
            idForm:this.props.idForm,
            selected: 0
        }
        const result = await updateRulesInit(body, this.state.id_rule);
        console.log('result.data :', result);
        if(result.data.result){
            
            toast.success('Se guardo la configuracion para inicializar',{
                position: toast.POSITION.BOTTOM_CENTER
            })
        }else{
            toast.error('Error al guardar la configuracion para inicializar',{
                position: toast.POSITION.BOTTOM_CENTER
            })
        }
    }

    
   addRule(){

    this.setState({
        modal: true,
        modalAllRules: false,
        update: false
    })

   }

   getRuleById(id){
    this.setState({
        modalAllRules: false,
        update: true,
        id_rule: id
    })
    this.toggle(id)
   }

   async setSelected(e,id,idForm){
       let selectedCheck =   e.target
       console.log('selectedCheck :', document.getElementsByName('ruleinits'));
       if(!selectedCheck){
        return
       }
        if(selectedCheck.checked){
            let bodyTrue = {
                id: id,
                idForm:idForm,
                selected: 1
            }
            const resultTrue = await setRulesInitSelected(bodyTrue)
            console.log('resultTrue :', resultTrue);
            if(resultTrue.ok){
                toast.success('Se activo la regla',{
                    position: toast.POSITION.BOTTOM_CENTER
                })
                let checks = document.getElementsByName('ruleinits');
                
                for (let index = 0; index < checks.length; index++) {
                    checks[index].checked = false
                }
                selectedCheck.checked = true

                
            }else{
                toast.error('Error al activar la regla',{
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
        }


        if(!selectedCheck.checked){
            let bodyFalse = {
                id: id,
                idForm:idForm,
                selected: 0
            }
            const resultFalse = await setRulesInitSelected(bodyFalse)
            console.log('resultFalse :', resultFalse);
            if(resultFalse.ok){
                toast.success('Se desactivo la regla',{
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }else{
                toast.error('Error al desactivar la regla',{
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
        }
        
   }

  render() {
      console.log('!! this.state :', this.state);
    return (

      <div className="App padding">
      <ToastContainer/>
                <MDBCol onClick={()=>this.toggleAllRules()} onMouseOut={()=> this.setState({shadow: ''})} onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} size="12" className={"border radius padding " +this.state.shadow }>
                    <MDBIcon  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="mouse-pointer" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}} size="2x" className=" cursor"></MDBIcon>
                    <h5  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})}>Cargar Reglas</h5>
                </MDBCol>

                {/**
                ==============================================MODAL IMPORT RULE==========================
                */}


                <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={ ()=>this.toggle()} size="lg">
                  <MDBModalHeader toggle={()=>this.toggle()}>Selecione los campos que quiere inicializar</MDBModalHeader>
                  <MDBModalBody>
                  <MDBTable bordered>
                  <MDBTableHead>
                    <tr>
                        <th>Campo</th>
                        <th>Inicializar</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                   { this.props.data[0][1]&&
                    this.props.data[0][1].map((field,i)=>(
                        field.type==='select' && field.data_portal===false?(
                            <tr>
                                <td>
                                    <div className="form-group">
                                        <label htmlFor={field.label} >{field.label}</label>
                                        <select  onChange={ e => this.deleteRule(field.name, field.name+'select')}  key={i} id={field.name} className="browser-default custom-select">
                                        <option value="00">seleccione</option> 
                                            {field.values.map((option, i) =>(
                                                <option value={option.value}>{option.label}</option> 
                                            ))}   
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input
                                        type="checkbox"
                                        id={ field.name+'select'}
                                        onClick={e => this.initField(e,field.name,field.type) }
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                        :
                        field.type==='radio-group'?(
                            <tr>
                                <td>
                                        <div className="form-group">
                                        <label htmlFor={field.label}   ><b>{field.label}</b></label>
                                        <MDBRow>
                                        {field.values.map((radio, ir) =>(

                                            <MDBCol  size="6"  >
                                            
                                               
                                                <MDBInput 
                                                gap 
                                                onClick={ e => this.deleteRule(field.name, field.name+'radio')}
                                                name={field.name}
                                                value={radio.value}
                                                className="form-control"
                                                key={ir} 
                                                type="radio"
                                                label={radio.label}
                                                id={field.name+radio.value} />
                                                <br/><label>{radio.value}</label>
                                                                 
                                            
                                            </MDBCol>
                                        
                                        ))}
                                        </MDBRow>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input
                                        type="checkbox"
                                        id={ field.name+'radio'}
                                        onClick={e => this.initField(e,field.name,field.type) }
                                        />
                                    </div>
                                </td>
                        </tr>
                        )
                        :
                        field.type === 'checkbox-group' ? (
                        
                            <tr>
                                <td>
                                        <div className="form-group">      
                                            <MDBRow>
                                            {field.values.map((radio, i) =>([
                                            <MDBCol size="6"  >
                                            <input
                                            type="checkbox"
                                            name={field.name}
                                            value={radio.value}
                                            key={i}
                                            id={field.name}
                                            onClick={ e => this.deleteRule(field.name, field.name+'check')}
                                            />
                                            <label>{radio.label}</label>
                                            </MDBCol>
                                            ]
                                            ))}
                                            </MDBRow>
                                        </div>
                                </td>
                            <td>
                                <div className="form-group">
                                    <input
                                    type="checkbox"
                                    id={ field.name+'check'}
                                    onClick={e => this.initField(e,field.name,field.type) }
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                    :
                    field.type === 'text' && (
                    
                        <tr>
                            <td>
                                    <div className="form-group">      
                                        <MDBRow>
                                        <MDBCol size="12"  >
                                        <label>{field.label}</label>
                                        <input
                                        type="text"
                                        name={field.name}
                                        id={field.name}
                                        className="form-control"
                                        key={i}
                                        onClick={e => this.initField(e,field.name,field.type)}
                                        />
                                        
                                        </MDBCol>
                                        </MDBRow>
                                    </div>
                            </td>
                        <td>
                            <div className="form-group">
                                <input
                                type="checkbox"
                                id={ field.name+'text'}
                                onClick={e => this.initField(e,field.name,field.type) }
                                />
                            </div>
                        </td>
                    </tr>
                )
                    ))}
                    </MDBTableBody>
                    </MDBTable>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn color="default" onClick={()=> this.state.update? this.updateRuleInit() :this.saveRuleInit()}>Guardar Configuracion</MDBBtn>
                  </MDBModalFooter>
                </MDBModal>
              </MDBContainer>


            {/**
                ==============================================MODAL ALL RULE==========================
            */}


            <MDBContainer>
                <MDBModal isOpen={this.state.modalAllRules} toggle={ ()=>this.toggleAllRules()} size="lg">
                  <MDBModalHeader toggle={()=>this.toggleAllRules()}>Todas las reglas de cargar</MDBModalHeader>
                  <MDBModalBody>
                    <MDBRow>
                    {this.state.AllRules.length>0&&
                        this.state.AllRules.map((rule,i)=>(
                            <MDBCol size="3" >
                                <input type="checkbox" name="ruleinits" id={rule.id+'check'} onClick={(e)=> this.setSelected(e,rule.id,rule.idForm)} />
                                <br/><br/>
                                <MDBCard style={{height: 120}} onClick={()=> this.getRuleById(rule.id)}>
                                <br/>
                                <MDBIcon   icon="mouse-pointer" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}} size="3x" className=" cursor"/>
                                <br/>    
                                <div  style={{paddingTop: 5,color: 'white',backgroundColor: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}><label> Regla no. {rule.id}</label></div>
                                </MDBCard>
                                
                                <br/><br/>
                            </MDBCol>
                    ))}
                            <MDBCol size="3">
                            <br/><br/>
                                <MDBCard style={{height: 120}} onClick={()=>this.addRule()}>
                                <br/>
                                <MDBIcon   icon="plus" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}} size="3x" className=" cursor"/>
                                <br/>    
                                <div  style={{paddingTop: 5,color: 'white',backgroundColor: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}><label> Agregar Regla</label></div>
                                </MDBCard>
                                <br/><br/>
                            </MDBCol>
                    </MDBRow>
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
        
      </div>
    );
  }
}

export default BtnImportRule;