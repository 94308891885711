import React, { Component } from 'react';
import CardText from "../../Utilities/CardText";
export default class FHeader extends Component {
    componentWillReceiveProps(props){
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group" >
          {field.subtype === 'h1' ? (
            <CardText key={i} id={field.name}  fs={false} color={process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'} text={field.label}/>
          )
          :
          (
            <CardText key={i} id={field.name} fs={true} color="#bdbdbd " text={field.label }/>
          )
        
        
        }
            
           
        </div>
      );
    }
  }