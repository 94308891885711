import React, { Component } from 'react';
export default class FSelect extends Component {
  
    componentWillReceiveProps(props){
      
      
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group">
          <label htmlFor={field.label} >{field.label}</label>
          <select   key={i} id={ 'F'+field.name} onChange={(e) => this.props.handlerFromInput(field.name, field.type,{label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text , value: e.target.value}, field.label,e)} >
          <option  key={i} value={'00'}>seleccione</option>
          {field.values&&  field.values.map((option, i)=>(
            <option  key={i} value={option.value}>{option.label}</option>
          ))}
            
            
          </select>
        </div>
      );
    }
  }

