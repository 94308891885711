import React, { Component } from 'react';
import Types from "prop-types";
import './App.css';
import  Content  from "../Body/content";
import Header from "../Body/header";
import {toast} from 'react-toastify';
import { User } from "../../data/index";
import ErrorBoundary from '../Errors/errorBoundary'
  const { logiForToken } = User
class App extends Component {

  constructor(props){
    super(props)
    this.idleTimer = null
    this.state = {
      isLogin: false,
      modal: false,
      loading: false,
      pass: '',
      user: ''
    }
    this.isLogin = this.isLogin.bind(this)
    this.toggle = this.toggle.bind(this)
    this.UserInput = this.UserInput.bind(this);
    this.PassInput = this.PassInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  static Types = {
    children: Types.object.isRequired
  }
  async componentWillReceiveProps(next){
    if(next !== this.props){
      // const result = await isAuth()

      // if(result.code === 401){
      //   this.setState({
      //     isLogin: false,
      //     modal: true
      //   })
      //   this.logout()
      // }
    }
  }


  
  handleKeyPress(e,action) {
    if(e.key === 'Enter'){
      switch(action) {
        case 'relogin':
          this.Login()
          break;
        default:
          break
      }
    }
  }
  isLogin(login){
    this.setState({
      isLogin: login
    })
  }
  
  toggle = () =>{

  }

 

  logout(){

    localStorage.setItem('token-rules','')
    localStorage.setItem('nameUser', '')
    localStorage.setItem('idUser', '')
    localStorage.setItem('isLogin', false)
    localStorage.setItem('rol', 0)
    localStorage.setItem('userLoggedIn', false)
    
     this.setState({
       isLogin: false,
       modal: false
     })
  }

  UserInput(e){
    this.setState({
      user: e.target.value
    })
  }

  PassInput(e){
    this.setState({
      pass: e.target.value
    })
  }


  async Login(){
    this.setState({
      loading: true
    })
    const result = await logiForToken(this.state.user, this.state.pass)
    if(result.ok){
      
      localStorage.setItem('token-rules', result.data.token_rules)
      localStorage.setItem('nameUser', result.data.user.user.firstname)
      localStorage.setItem('idUser', result.data.user.user.id)
      localStorage.setItem('isLogin', true)
      localStorage.setItem('rol', 2)
      localStorage.setItem('userLoggedIn', result.data.user.user.email)
      toast.info('su sesion inicio nuevamente',{
        position: toast.POSITION.TOP_RIGHT
      })
      window.location.reload()
      
      this.setState({
        modal: false,
        isLogin: true,
      })
    
    }else{
      console.log('result.data :', result.data);
    }
    this.setState({
      loading: false,
    })
  
  }
  render() {

    const {children} = this.props
    if(window.location.pathname === '/' || window.location.pathname === '/login'){
      return(
        <div className="App">
        
        <ErrorBoundary>
          <Content isLogin={this.isLogin} body={children}/>
        </ErrorBoundary>
          
        </div>
      )
    }
    return (
      <div className="App">
      <ErrorBoundary>
        <Header isLogin={this.isLogin} />
        <Content isLogin={this.isLogin} body={children}/>
      </ErrorBoundary>
      </div>
    );
  }
}
export default App;
