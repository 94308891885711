
import axios from "axios";
function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
 return axios
}


    function saveRulesPrice(body) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice').post(process.env.REACT_APP_API+'/ruleprice',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }



    function getRulesPriceSelected(id,type) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/selected/'+id+'/'+type).get(process.env.REACT_APP_API+'/ruleprice/selected/'+id+'/'+type).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }

    function setRulesPriceSelected(body) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/selected').post(process.env.REACT_APP_API+'/ruleprice/selected',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }


    function removeRulesPriceSelected(body) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/selected/remove').post(process.env.REACT_APP_API+'/ruleprice/selected/remove',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }



    function updateRulesPrice(body,id) {
        console.log('data :', body)
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/'+id).put(process.env.REACT_APP_API+'/ruleprice/'+id,body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }


    function GetRulesPriceByForm(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/'+id).get(process.env.REACT_APP_API+'/ruleprice/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }



    function GetRulesPriceByPrice(id,rule,type) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/price/'+id+'/'+rule+'/'+type).get(process.env.REACT_APP_API+'/ruleprice/price/'+id+'/'+rule+'/'+type).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }



    function GetRulesPriceById(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/id/'+id).get(process.env.REACT_APP_API+'/ruleprice/id/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }

    function deleteRulesPriceByForm(id) {
        const result =  axios_api(process.env.REACT_APP_API+'/ruleprice/'+id).delete(process.env.REACT_APP_API+'/ruleprice/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }


    export {
        GetRulesPriceByForm,
        updateRulesPrice,
        deleteRulesPriceByForm,
        saveRulesPrice,
        GetRulesPriceById,
        setRulesPriceSelected,
        GetRulesPriceByPrice,
        removeRulesPriceSelected,
        getRulesPriceSelected
    }