import React, { Component } from 'react';
import {MDBDataTable, MDBIcon,MDBBtn} from "mdbreact";
import {Form, Roles} from "../../data/index";

import {toast,ToastContainer} from 'react-toastify';
const {GetAllFormularyDataTable} = Form
const {GetRolesByUserAndForm} = Roles
export class MasksTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            AllForm:[],
            idForm:'',
            id:'',
            data2:[],
            serviceName:'',
            formName:'',
            loading:true
          }
        this.toMaskFields = this.toMaskFields.bind(this)
    }
    async componentDidMount(){
        const data = await GetAllFormularyDataTable();
        console.log(data.data.Formulary , 'Formulary')
        let array = []
        try{
            for (const i of data.data.Formulary) {
              console.log('i', i)
              let element = {
                id: i.id,
                idForm: i.idForm,
                service: i.service_name,
                institution: i.institution_name,
                Máscara: <MDBBtn className="btn  btn-md text-white" color={process.env.REACT_APP_EXECUTION_ENV==='production'? process.env.REACT_APP_COLOR_PRODUCTION: process.env.REACT_APP_COLOR_DEVELOPMENT} onClick={()=> this.toMaskFields(i)}  
                
                >Asignar Máscaras</MDBBtn>
              }
              array.push(element)
              this.state.loading = false;
            }
          }catch(e){console.log(e)}
        const column = [
          {label:'id',field:'id',sort:'asc'},
          {label:'formulario',field:'idForm',sort:'asc'},
          {label:'Servicio',field:'service',sort:'asc'},
          {label:'Institucion',field:'institution',sort:'asc'},
          {label:'Accion',field:'Máscara',sort:'asc'},
        ]
        const result = {columns:column, rows: array}
        this.setState({
          AllForm: result,
        })
      }



    async toMaskFields(form){ 
      this.state.loading = true
      const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"),form.id)
      if(!roles.data.rol){
        this.setState({
          loading: 0
        })
        toast.error('Usted no tiene permiso para ver este formulario', {
          position: toast.POSITION.BOTTOM_CENTER
        })
        return
      }
      this.props.history.push({
        pathname: '/masklist',
        state: form
      })
    }




    render() {
      return(
          <div className="contentTable">
            <ToastContainer/>
          <h1>Formularios para Máscaras</h1>
          <br/>
            <MDBDataTable
                striped
                bordered
                hover
                data={this.state.AllForm}
            />
            {this.state.loading? (<MDBIcon icon="cog" spin size="3x" style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}fixed />):''}
          </div>
      ) 
    }
}
export default MasksTable;