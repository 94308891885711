import React, { Component } from 'react'

import { MDBIcon,MDBBtn, MDBRow, MDBCol} from "mdbreact";
import { Mask } from "../../data/index";
import MaskBox from './maskBox'
import BtnNewmask from './btnNewMask';
const { GetAllMaskVersionsPerForm} = Mask

export default class maskList extends Component {


    constructor(props){
        super(props)
        this.state = {
            masks: [],
            loading: false,
            form: [],
             newMask: false
        }
    }

   async  componentDidMount(){
        const {location} = this.props;
        const form = location.state;
        this.setState({
            loading: true
        })
        if(form){
            const result = await GetAllMaskVersionsPerForm(form.id);
            if(result.ok){
                this.setState({
                    masks: result.data.mask,
                    form: form
                })
            }
        }
        if(!form){
            this.props.history.push('/Mask')
        }

        this.setState({
            loading: false
        })
        

    }

    render() {
        console.log('this.state.masks', this.state.masks)
        return (
            <div>
                <MDBRow>
                    <MDBCol size="3"><BtnNewmask form={this.state.form}/></MDBCol>
                    <MDBCol size="3"></MDBCol>
                    <MDBCol size="3"></MDBCol>
                    <MDBCol size="3"></MDBCol>
                </MDBRow>
                {this.state.loading&&(
                    <MDBIcon 
                    icon="cog" 
                    spin 
                    size="3x" 
                    style={{color: process.env.REACT_APP_EXECUTION_ENV==='production'? '#00695c': '#0d47a1'}}
                    fixed />
                )}

                {this.state.masks.length===0&&(
                    <h2>No hay version de mascaras</h2>
                )}

               <MDBRow>
               {this.state.masks.length>0&&
                this.state.masks.map((mask,i)=>(
                    <MaskBox mask={mask} form={this.state.form} key={i}/>
                ))}
               </MDBRow>
            </div>
        )
    }
}
